/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";

const ChevronRight: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
            <mask id='mask0_80_4077' width={24} height={24} x={0} y={0} maskUnits='userSpaceOnUse' style={{
      maskType: "alpha"
    }}>
                <path fill='currentColor' d='M0 0h24v24H0V0Z' />
            </mask>
            <g mask='url(#a)'>
                <path fill='currentColor' d='M8.7 17.3a.948.948 0 0 1-.275-.7c0-.283.092-.517.275-.7l3.9-3.9-3.9-3.9a.948.948 0 0 1-.275-.7c0-.283.092-.517.275-.7a.948.948 0 0 1 .7-.275c.283 0 .517.092.7.275l4.6 4.6c.1.1.171.208.213.325.041.117.062.242.062.375s-.02.258-.062.375a.883.883 0 0 1-.213.325l-4.6 4.6a.948.948 0 0 1-.7.275.948.948 0 0 1-.7-.275Z' />
            </g>
        </svg>;
};

export default ChevronRight;